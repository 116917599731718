/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from "react";
import { graphql } from "gatsby";
import { get, camelCase, upperFirst } from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";
import queryString from "query-string";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";

import { ContentBlock } from "../components/Blocks";
import Masonry from "../components/Masonry";
import Pages from "../components/Pages";
import BannerCarousel from "../components/BannerCarousel";
import { Share, SiteMetadataContext } from "../widgets";
import NoScript from "../components/NoScript";
import { makeJulianDate, removeEmptyKeys, removePreloader } from "../helpers";
import { showMore } from "../constants";
import { useThemeContext } from "../components/Layout/ThemeContext";

import { MAIN_PAGE, MAIN_PAGE_CONTENT, MAIN_PAGE_INITIAL_DATA } from "../queries/queries.graphql";

import "../styles/mainpage.scss";

export const query = graphql`
	query mainPageQuery($currentDay: HASURA_date!) {
		hasura {
			...MainPage
		}
	}
`;

export default function MainPage({ data, location }) {
	const { theme } = useThemeContext();
	const { siteMetadata } = useContext(SiteMetadataContext);

	const parsed = queryString.parse(location.search);
	const targetKey = parsed.utm_term || "default";
	const maxUpdated = new Date(get(data, "hasura.v_main_page_content_aggregate.aggregate.max.updated_at", new Date()));

	const [mainPage, setMainPageData] = useState(get(data, "hasura", {}));
	const [moreData, setMoreData] = useState({
		offset: 10,
		pageData: [],
	});
	const [broadcastList, setBroadcastList] = useState([]);

	const bannerSettings = get(mainPage, "main_page[0].settings.banners", {});

	/**
	 * Load max update and broadcast
	 */
	const { loading: maxUpdateLoading, error: maxUpdateError, data: maxUpdateData } = useQuery(
		MAIN_PAGE_INITIAL_DATA,
		{
			fetchPolicy: "cache-and-network",
			ssr: false,
		},
	);

	/**
	 * Load new page data
	 */
	const [mainPageLoad, { called: mainPageCalled, loading: mainPageLoading, error: mainPageError, data: mainPageData }] = useLazyQuery(
		MAIN_PAGE,
		{
			variables: {
				targetKey,
				currentDay: makeJulianDate("-"),
				ymd: makeJulianDate("", -13),
			},
			fetchPolicy: "cache-and-network",
			ssr: false,
		},
	);

	/**
	 * Load more
	 */
	const [loadMore, { called: loadMoreCalled, loading: loadMoreLoading, error: loadMoreError, data: loadMoreData }] = useLazyQuery(
		MAIN_PAGE_CONTENT,
		{
			variables: {
				targetKey,
			},
			fetchPolicy: "cache-and-network",
			partialRefetch: true,
		},
	);

	/**
	 *
	 * @param {Array} data Remove data from fixedContent, and format for Tile component
	 */
	function formatData(data) {
		const fixedContent = get(mainPage, "main_page_fixed_contents", []);

		return data
			// TODO: Need to optimimze id naming
			.filter(({ id }) => !fixedContent.find(({ news_id, media_article_id }) => id === news_id || id === media_article_id))
			.map(item => (
				{
					type: upperFirst(camelCase(item.type)),
					data: item,
				}
			));
	}

	function transformData() {
		const mainData = formatData([...get(mainPage, "v_main_page_content", []), ...get(moreData, "pageData", [])]);
		const fixedContent = get(mainPage, "main_page_fixed_contents", []);

		const modifiedData = fixedContent.reduce((result, item) => {
			const { components, position } = item;

			if (components.type === "News") {
				const { news } = item;

				result.splice(
					position,
					0,
					{
						type: "News",
						data: news,
					},
				);
			}

			if (components.type === "Calendar") {
				result.splice(
					position,
					0,
					{
						type: "Calendar",
						data: get(mainPage, "days[0]", {}),
					},
				);
			}

			if (components.type === "List") {
				result.splice(
					position,
					0,
					{
						type: "List",
						data: [
							{
								title: "Все статьи",
								items: get(mainPage, "articles", []),
								link: "/articles",
							},
							{
								title: "Все новости",
								items: get(mainPage, "news", []),
								link: "/news",
							},
						],
					},
				);
			}

			if (components.type === "MediaArticle") {
				const { media_article } = item;

				result.splice(
					position,
					0,
					{
						type: "MediaArticle",
						data: media_article,
					},
				);
			}

			if (components.type === "Video") {
				const { media_video } = item;

				result.splice(
					position,
					0,
					{
						type: "Video",
						data: media_video,
					},
				);
			}

			if (components.type === "Tour") {
				const { tours } = item;

				result.splice(
					position,
					0,
					{
						type: "Tour",
						data: tours,
					},
				);
			}

			return result;
		}, mainData);

		modifiedData.unshift(...formatData(broadcastList));

		return modifiedData;
	}

	useEffect(() => {
		localStorage.setItem("targetKey", targetKey);
	}, []);

	useEffect(() => {
		if (maxUpdateData && !maxUpdateError) {
			const currentMaxUpdated = new Date(get(maxUpdateData, "v_main_page_updated_at[0].updated_at", new Date()));
			const broadcasts = get(maxUpdateData, "broadcasts", []);

			if (+currentMaxUpdated !== +maxUpdated) {
				mainPageLoad();
			} else {
				removePreloader(true, 800);
			}

			if (broadcasts.length) {
				setBroadcastList(broadcasts);
			}
		} else if (maxUpdateError) {
			console.error("Invalid load NewsMaxUpdate", { maxUpdateError });
			removePreloader(true, 800);
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (mainPageCalled && mainPageData && !mainPageError) {
			setMainPageData(mainPageData);
			removePreloader(mainPageCalled, 800);
		} else if (mainPageError) {
			console.error("Invalid load NewsData", { mainPageError });
			removePreloader(true, 800);
		}
	}, [mainPageLoading]);

	useEffect(() => {
		if (loadMoreCalled && loadMoreData && !loadMoreError) {
			const pageContent = get(loadMoreData, "v_main_page_content", []);

			setMoreData({
				offset: pageContent.length < 10 ? false : moreData.offset + 10,
				pageData: [...moreData.pageData, ...pageContent],
			});
		} else if (loadMoreError) {
			console.error("Invalid loadMore data", { loadMoreError });
		}
	}, [loadMoreLoading]);

	return (
		<Pages entity={get(mainPage, "main_page[0]", {})} url={"/"}>
			<ContentBlock className={"pt-4"}>
				<BannerCarousel
					settings={bannerSettings}
					items={get(mainPage, "banners", [])}
					className={"main-banner-carousel"}
				/>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<Masonry
					contentList={transformData()}
				/>
			</ContentBlock>
			<NoScript>
				<div>
					<Masonry
						contentList={formatData(get(mainPage, "v_main_page_content", []))}
					/>
				</div>
			</NoScript>
			<ContentBlock key={"load-more"}>
				<div className={"container"}>
					<div className={"row justify-content-center"}>
						<Button
							type={"primary"}
							size={"large"}
							className={"mb-4 d-noscript-none"}
							onClick={() => loadMore({
								variables: {
									offset: moreData.offset,
								},
							})}
							loading={loadMoreLoading}
							disabled={loadMoreLoading || !moreData.offset}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share pageTitleShort={(siteMetadata, "title", "Серафимова Земля")} url={"/"}/>
			</div>
		</Pages>
	);
}

MainPage.propTypes = {
	location: PropTypes.object,
	data: PropTypes.object,
};

MainPage.defaultProps = {
	location: {},
	data: {},
};
