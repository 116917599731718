import React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { isMobile, isTablet } from "react-device-detect";

import { Image } from "../Media/Image";
import { useThemeContext } from "../Layout/ThemeContext";
import { ymNumber } from "../../constants";

import "./TargetBanner.scss";
import { removeEmptyKeys } from "../../helpers";

function handleBannerClick(slug, params) {
	if (typeof window !== "undefined" && window.ym && process.env.NODE_ENV === "production") {
		if (localStorage.getItem("PUSHTELL-banner") !== null) {
		// eslint-disable-next-line no-undef
			ym(
				ymNumber,
				"reachGoal",
				"main-banner",
				{
					AB: {
						"main-banner": {
							[localStorage.getItem("PUSHTELL-banner")]: {
								url: slug,
							},
						},
					},
				},
			);
		}

		const ymBannerTarget = get(params, "seo.ym.goals", "");

		if (ymBannerTarget) {
			// eslint-disable-next-line no-undef
			ym(
				ymNumber,
				"reachGoal",
				ymBannerTarget,
			);
		}
	}
}

function getMedisSources(params) {
	const showMobileImg = !isTablet && isMobile;

	let medidSources = [];

	if (showMobileImg) {
		medidSources.push({
			src: get(params, "mobile_image.src", ""),
			media: "(max-width: 768px)",
		});
	}

	if (isTablet) {
		medidSources.push({
			src: get(params, "image_tablet.src", ""),
			media: "(max-width: 1366px)",
		});
	}

	return medidSources;
}

export function BannerImg({ params, link }) {
	return (
		<Link className={"link"} to={link} onClick={() => handleBannerClick(link, params)}>
			<div className={"target-banner target-banner__img"} style={{
				backgroundColor: get(params, "bgColor", ""),
			}}>
				{get(params, "border.enabled", false) && <div className={"target-banner__border-before"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
				<Image
					className={"target-banner__image"}
					src={get(params, "image.src", "")}
					minHeight={"100px"}
					height={"100%"}
					style={{
						height: "100%",
						width: "100%",
						objectFit: "contain",
					}}
					responsive={getMedisSources(params)}
				/>
				{get(params, "border.enabled", false) && <div className={"target-banner__border-after"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
			</div>
		</Link>
	);
}

// TODO: fix BannerText img make background like in CombinedBanner
export function BannerQuote({ params, link }) {
	const { theme } = useThemeContext();

	const makeMediaStyle = media => {
		return media.map(device =>
			`@media screen and (min-width: ${get(device, "breakpoint", 0)}px) {
				.target-banner {
					height: ${get(device, "bannerHeight", "auto")}px;
				}
				.target-banner__content picture {
					height: ${get(device, "bannerHeight", "auto")}px !important;
				}
				.target-banner__content picture img {
					height: ${get(device, "bannerHeight", "auto")}px;
				}
				.target-banner__content h3 {
					font-size: ${get(device, "textSize", 32)}px;
					line-height: ${get(device, "textSize", 32)}px;
					color: ${get(device, "textColor", "#fff")};
				}
				.target-banner__content h4 {
					font-size: ${get(device, "authorTextSize", 24)}px;
					line-height: ${get(device, "authorTextSize", 24)}px;
					color: ${get(device, "textColor", "#fff")};
				}
			}`,
		);
	};

	return (
		<Link
			className={"link"}
			to={link}
			onClick={() => handleBannerClick(link, params)}
		>
			<div className="target-banner" style={{
				backgroundColor: get(params, "bgColor", get(params, "bg_color", "")),
				backgroundImage: `url(${get(params, "image.src", "")})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				...theme.image,
			}}>
				<style>
					{`
					.target-banner {
						height: ${get(params, "bannerHeight", "auto")}px;
					}
					.target-banner__content picture {
						height: ${get(params, "bannerHeight", "auto")}px !important;
					}
					.target-banner__content picture img {
						height: ${get(params, "bannerHeight", "auto")}px;
					}
					.target-banner__content h3 {
						font-size: ${get(params, "textSize", 32)}px;
						line-height: ${get(params, "textSize", 32)}px;
						color: ${get(params, "textColor", "#fff")};
					}
					.target-banner__content h4 {
						font-size: ${get(params, "authorTextSize", 24)}px;
						line-height: ${get(params, "authorTextSize", 24)}px;
						color: ${get(params, "textColor", "#fff")};
					}

					${makeMediaStyle(get(params, "media", []))}
					`}
				</style>
				{get(params, "border.enabled", false) && <div className={"target-banner__border-before"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
				<div className={"container target-banner__content"}>
					<div className="row justify-content-center align-items-center">
						<div className="col-6 col-sm-5 col-lg-4 col-xl-3">
							<Image
								className={"target-banner__image"}
								src={get(params, "serafim_image.src")}
								minHeight={"100px"}
								responsive={getMedisSources(params)}
								style={{ maxHeight: 340,
									maxWidth: "100%",
									...theme.image }}
							/>
						</div>
						<div className="col-6 col-sm-7 col-lg-8 col-xl-9 py-3 pr-4">
							<h3
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.h3,
									lineHeight: theme.lineHeight.h3,
									color: theme.color.text,
								})}
								dangerouslySetInnerHTML={{ __html: get(params, "text", "") }}
							/>
							<h4
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.h4,
									lineHeight: theme.lineHeight.h4,
									color: theme.color.text,
								})}
								dangerouslySetInnerHTML={{ __html: get(params, "author", "") }}
							/>
						</div>
					</div>
				</div>
				{get(params, "border.enabled", false) && <div className={"target-banner__border-after"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
			</div>
		</Link>
	);
}
export function CombinedBanner(props) {
	const { theme } = useThemeContext();

	const { params } = props;

	const makeMediaStyle = media => {
		return media.map(device =>
			`@media screen and (min-width: ${get(device, "breakpoint", 0)}px) {
				.target-banner, combined-banner__item {
					height: ${get(device, "bannerHeight", "auto")}px;
				}
				.combined-banner__title {
					font-size: ${get(device, "textSize", 32)}px;
					color: ${get(device, "textColor", "#fff")};
				}
				.combined-banner__img img {
					height: ${get(device, "imgHeight", 150)}px;
				}
			}`,
		);
	};

	return (
		<div
			className="target-banner combined-banner d-flex justify-content-center align-items-center"
			style={{
				backgroundColor: get(params, "bgColor", ""),
				backgroundImage: `url(${get(params, "image.src", "")})`,
				backgroundPosition: "center",
				backgroundSize: "cover",
				...theme.image,
			}}
		>
			<style>
				{`
				.target-banner, combined-banner__item {
					height: ${get(params, "bannerHeight", "auto")}px;
				}
				.combined-banner__title {
					font-size: ${get(params, "authorTextSize", 24)}px;
					color: ${get(params, "textColor", "#fff")};
				}
				.combined-banner__img img {
					height: ${get(params, "imgHeight", 150)}px;
				}

				${makeMediaStyle(get(params, "media", []))}
				`}
			</style>
			{get(params, "border.enabled", false) && <div className={"target-banner__border-before"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
			{get(params, "items", []).map((item, idx) => {
				const title = get(item, "title", "");

				return (
					<Link
						key={idx}
						to={get(item, "link", "")}
						className={"combined-banner__item"}
						onClick={() => handleBannerClick("combinedBanner", params)}>
						<div className={"combined-banner__img"}>
							<img src={get(item, "image.src", "")} alt={title} style={{ ...theme.image }} />
						</div>
						{title && <span className={"combined-banner__title"}>{title}</span>}
					</Link>
				);
			})}
			{get(params, "border.enabled", false) && <div className={"target-banner__border-after"} style={{ border: `1px solid ${get(params, "border.color", "#fff")}` }}></div>}
		</div>
	);
}

BannerImg.propTypes = {
	params: PropTypes.object,
	link: PropTypes.string,
};
BannerQuote.propTypes = {
	params: PropTypes.object,
	link: PropTypes.string,
};
CombinedBanner.propTypes = {
	params: PropTypes.object,
};
