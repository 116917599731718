import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import { isMobile, isTablet } from "react-device-detect";
import { Icon } from "antd";

import * as TargetBanner from "../TargetBanner";

import "./BannerCarousel.scss";

export default function BannerCarousel({ items, settings, className }) {
	const arrowColor = get(settings, "arrowColor", "#fff");
	const autoPlay = get(settings, "enabled", false);
	const interval = get(settings, "interval", 5) * 1000;

	if (!Array.isArray(items) || Array.isArray(items) && !items.length) {
		return null;
	}
	const bannerSlides = items.filter(item => get(item, "params.enabled", false) && get(item, "params.locations", "").match(/mainPage/));

	let naturalSlideHeight = 14.5;

	if (isMobile) {
		naturalSlideHeight = get(bannerSlides, "[0].params.bannerMobileNaturalHeight", 60);
	}

	if (isTablet) {
		naturalSlideHeight = get(bannerSlides, "[0].params.bannerTabletNaturalHeight", 21);
	}

	return (
		<CarouselProvider
			naturalSlideWidth={100}
			naturalSlideHeight={naturalSlideHeight}
			totalSlides={bannerSlides && bannerSlides.length}
			visibleSlides={1}
			dragEnabled={false}
			touchEnabled={bannerSlides && bannerSlides.length > 1}
			infinite={true}
			isPlaying={autoPlay}
			interval={interval}
			className={className}
		>
			<Slider>
				{
					bannerSlides.map((item, idx) => {
						return (
							<Slide
								key={idx}
								index={idx}
								className={"banner__slide"}
							>
								{
									React.createElement(TargetBanner[get(item, "params.type", "")] || TargetBanner.BannerImg, {
										...item,
									})
								}
							</Slide>
						);
					})
				}
			</Slider>

			{
				bannerSlides.length > 1 && !isMobile && (
					<>
						<ButtonBack className={"banner__btn banner__btn_left"}>
							<Icon
								type="left"
								style={{
									fontSize: "48px",
									color: arrowColor,
								}}
							/>
						</ButtonBack>
						<ButtonNext className={"banner__btn banner__btn_right"}>
							<Icon
								type="right"
								style={{
									fontSize: "48px",
									color: arrowColor,
								}}
							/>
						</ButtonNext>
					</>
				)
			}
		</CarouselProvider>
	);
}

BannerCarousel.propTypes = {
	items: PropTypes.array,
	settings: PropTypes.object,
	className: PropTypes.string,
};

BannerCarousel.defaultProps = {
	items: [],
	settings: {},
	className: "",
};
